import React from 'react';
import Logo from "../assets/images/LOGO-MACRO-PRO-CAR.png";
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <footer className="tj-footer-area footer-v2">
      <div className="footer-menu-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="footer-widget footer1-col-1 footer-widget-content">
                <div className="footer-logo">
                <Link to="/">
                  <img src={Logo} alt="Logo" style={{ width: "200%" }} />
                </Link>
                </div>
                <p className="desc">
                  Explorando los megaproyectos en Cartagena de Indias, transformamos el futuro con innovación y sostenibilidad, promoviendo el desarrollo económico y mejorando la calidad de vida.
                </p>
                <div className="footer-social-list">
                  <ul className="list-gap">
                    <li>
                      <a href="#"> <i className="fa-brands fa-linkedin-in"></i></a>
                    </li>
                    <li>
                      <a href="#"> <i className="fa-brands fa-facebook-f"></i></a>
                    </li>
                    <li>
                      <a href="#"> <i className="fa-brands fa-twitter"></i></a>
                    </li>
                    <li>
                      <a href="#"> <i className="fa-brands fa-instagram"></i></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 col-6">
              <div className="footer-widget footer1-col-2 widget_nav_menu">
                <h6 className="title">Proyectos</h6>
                <div className="footer-menu-list">
                  <ul>
                    <li><a href="#">Puerto de Aguas Profundas</a></li>
                    <li><a href="#">Renovación Urbana</a></li>
                    <li><a href="#">Infraestructura Vial</a></li>
                    <li><a href="#">Parques Eólicos</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 col-6">
              <div className="footer-widget footer1-col-3 widget_nav_menu">
                <h6 className="title">Sobre Nosotros</h6>
                <div className="footer-menu-list">
                  <ul>
                    <li><a href="#">Quiénes somos</a></li>
                    <li><a href="#">Visión</a></li>
                    <li><a href="#">Misión</a></li>
                    <li><a href="#">Contacto</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 col-6">
              <div className="footer-widget footer1-col-4 widget_nav_menu">
                <h6 className="title">Recursos</h6>
                <div className="footer-menu-list">
                  <ul>
                    <li><a href="#">Blog</a></li>
                    <li><a href="#">Estudios de Caso</a></li>
                    <li><a href="#">Informes</a></li>
                    <li><a href="#">Publicaciones</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 col-6">
              <div className="footer-widget footer1-col-5 widget_nav_menu">
                <h6 className="title">Ayuda</h6>
                <div className="footer-menu-list">
                  <ul>
                    <li><a href="#">FAQs</a></li>
                    <li><a href="#">Soporte Técnico</a></li>
                    <li><a href="#">Guías de Usuario</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <p className="desc text-center">
                Copyright © 2024 <a href="#" target="_blank"> Macropoyectos Cartagena de Indias. </a> All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_shape">
        <img src="assets/images/shape/overly-10.svg" alt="Shape" />
      </div>
      <div className="footer_shape1">
        <img src="assets/images/shape/sec-shape9.svg" alt="Shape" />
      </div>
      <div className="footer-star-group">
        <img className="star-1" src="assets/images/icon/star-1.png" alt="Icon" />
        <img className="star-2" src="assets/images/icon/star-2.png" alt="Icon" />
        <img className="star-3" src="assets/images/icon/star-3.png" alt="Icon" />
      </div>
    </footer>
  );
};

export default Footer;
