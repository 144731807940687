import React from "react";

const TeamSection = () => {
  // Define los IDs de los videos de YouTube para cada miembro del equipo
  const teamVideos = {
    roger: "https://www.youtube.com/embed/YegYy-3jJFA?si=vvEnMmf01uNMLKw8",
    melissa: "ID_DEL_VIDEO_DE_YOUTUBE_DEMO_PARA_MELISSA",
    // Añade más miembros del equipo aquí si es necesario
  };

  return (
    <section className="tj-team-section">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5">
            <div className="team-content-one">
              <div className="tj-sec-heading">
                <div className="team-span">
                  <span className="sub-title"> Visión</span>
                </div>

                <h2 className="title">
                  Vision de los nuevos proyectos de cartagena
                </h2>
                <p className="desc"></p>
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="swiper tj-team-slider">
              <div className="swiper-wrapper">
                {/* Roger Patel */}
                <div className="swiper-slide">
                  <div className="tj-team-item hover-shape-border">
                    <div
                      className="team-image-box"
                      style={{
                        position: "relative",
                        width: "100%",
                        paddingTop: "56.25%",
                      }}
                    >
                      <iframe
                        src={`https://www.youtube.com/embed/YegYy-3jJFA?si=vvEnMmf01uNMLKw8`} // Asegúrate de usar el formato correcto del enlace
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Roger Patel"
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "0",
                          width: "100%",
                          height: "100%",
                        }}
                      ></iframe>
                    </div>
                  </div>
                </div>
                {/* Melissa Lee */}
                <div className="swiper-slide">
                  <div className="tj-team-item hover-shape-border">
                    <div className="team-image-box">
                      <iframe
                        src={`https://www.youtube.com/embed/${teamVideos.melissa}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Melissa Lee"
                        style={{ width: "100%", height: "100%" }} // Asegúrate de ajustar el tamaño según sea necesario
                      ></iframe>
                      <div className="team-content">
                        <div className="team-header">
                          <h5>
                            <a className="title-link" href="#">
                              {" "}
                              Melissa Lee
                            </a>
                          </h5>
                          <span className="sub-title"> UI UX Designer</span>
                        </div>
                        <div className="team-social">
                          <ul>{/* Links de redes sociales */}</ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Añadir más miembros del equipo aquí */}
              </div>
              <div className="swiper-pagination"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="team-shape pulse">
        <img src="assets/images/shape/sec-shape2.svg" alt="Shape" />
      </div>
      <div className="team-shape1">
        <img src="assets/images/shape/sec-shape1.svg" alt="Shape" />
      </div>
      <div className="team-shape2">
        <img src="assets/images/shape/overly-4.svg" alt="Shape" />
      </div>
    </section>
  );
};

export default TeamSection;
