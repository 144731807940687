import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, TwitterAuthProvider, OAuthProvider } from 'firebase/auth';
import { getFirestore, doc, getDoc, where, query, getDocs,collection, updateDoc,addDoc,setDoc , collectionGroup, } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getMessaging } from 'firebase/messaging';
import { deleteDoc } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
const firebaseConfig = {
  apiKey: "AIzaSyDA2MJ4ct--G_4JSmFqED0mxM0MQiRjqnQ",
  authDomain: "macropoyectos-cartagena.firebaseapp.com",
  databaseURL: "https://macropoyectos-cartagena-default-rtdb.firebaseio.com",
  projectId: "macropoyectos-cartagena",
  storageBucket: "macropoyectos-cartagena.appspot.com",
  messagingSenderId: "213965874909",
  appId: "1:213965874909:web:e307ddc3aa6e9a41219d34",
  measurementId: "G-PCEWFL4RFP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const storage = getStorage(app);
const messaging = getMessaging(app);

const db = getFirestore(app);

const googleProvider = new GoogleAuthProvider();
const twitterProvider = new TwitterAuthProvider();
const linkedinProvider = new OAuthProvider('linkedin.com');
const microsoftProvider = new OAuthProvider('microsoft.com');
const functions = getFunctions(app);
export {
  auth,
  googleProvider,
  twitterProvider,
  linkedinProvider,
  microsoftProvider,
  db,
  storage,
  messaging,
  deleteDoc,
  doc,
  getDoc, 
  where,
  query,
  getDocs,
  collection,
  updateDoc,
  addDoc,setDoc,
  collectionGroup,
  functions,
};
