import React from "react";
import MenuData from "./Menudata";
import { Link } from "react-router-dom";
import Logo from "../assets/images/LOGO-MACRO-PRO-CAR.png";
import { logout } from "../services/Authservices";
import Swal from "sweetalert2";

const Header = () => {
  // Handler para el logout
  const handleLogout = async () => {
    try {
      await logout();
      Swal.fire({
        title: 'Sesión cerrada con éxito',
        text: 'Has cerrado sesión exitosamente.',
        icon: 'success',
        confirmButtonText: 'Ok'
      });
    } catch (error) {
      console.error("Error al cerrar sesión:", error.message);
      Swal.fire({
        title: 'Error',
        text: 'Error al cerrar sesión. Inténtalo de nuevo.',
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }
  };

  return (
    <>
      <header className="tj-header-area" id="tj-header-sticky">
        <div className="container">
          <div className="row">
            <div className="header-content-two">
              <div className="logo-area">
                <Link to="/">
                  <img src={Logo} alt="Logo" style={{ width: "200%" }} />
                </Link>
              </div>

              <div
                className="tj-main-menu d-lg-block d-none text-center"
                id="main-menu"
              >
                <MenuData />
              </div>

              <div className="header-button-box d-lg-block d-none">
                <div className="tj-login-button header-button">
                  <Link to="/login" className="tj-black-btn">
                    Login
                  </Link>
                </div>
                <div className="tj-singup-button header-button">
                  <button onClick={handleLogout} className="tj-primary-black-btn">
                    Sing up
                  </button>
                </div>
              </div>
              <div className="tj-canva-icon d-lg-none">
                <a
                  className="canva_expander nav-menu-link menu-button"
                  href="#"
                >
                  <span className="dot1"></span>
                  <span className="dot2"></span>
                  <span className="dot3"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
