import { db, collection, query, where, getDocs } from "../firebase";
import axios from "axios";
import Swal from "sweetalert2";
import algoliasearch from "algoliasearch/lite";
import withReactContent from 'sweetalert2-react-content';
import './customSwalStyles.css';
// Objeto de mapeo para las conversiones de nombres de barrios a direcciones específicas
const direccionMapeo = {
  "Tesca": "Cra. 49a #31-125",
  // Añade más mapeos según sea necesario
};

const MySwal = withReactContent(Swal);
const procesarDocumento = (doc) => {
  if (!doc.exists) {
    console.error("El documento no existe");
    return null;
  }

  const data = doc.data();

  const { Nombre, ...restoDatos } = data;

  return {
    Nombre: Nombre || "Datos no disponible",
    "AreaConstruida (m2)": restoDatos["AreaConstruida (m2)"] || "Datos no disponible",
    "AreaTerreno (m2)": restoDatos["AreaTerreno (m2)"] || "Datos no disponible",
    "Avaluo ($)": restoDatos["Avaluo ($)"] || "Datos no disponible",
    Comuna: restoDatos.Comuna || "Datos no disponible",
    Departamento: restoDatos.Departamento || "Datos no disponible",
    DestinoEconomico: restoDatos.DestinoEconomico || "Datos no disponible",
    DestinoEconomicoHomologado: restoDatos.DestinoEconomicoHomologado || "Datos no disponible",
    Direccion: restoDatos.Direccion || "Datos no disponible",
    EstadoCivil: restoDatos.EstadoCivil || "Datos no disponible",
    Municipio: restoDatos.Municipio || "Datos no disponible",
    NoDocumentoGo: restoDatos.NoDocumentoGo || "Datos no disponible",
    NoOrden: restoDatos.NoOrden || "Datos no disponible",
    NoPredial: restoDatos.NoPredial || "Datos no disponible",
    NoPredialAnterior: restoDatos.NoPredialAnterior || "Datos no disponible",
    TipoDocumento: restoDatos.TipoDocumento || "Datos no disponible",
    TipoRegistro: restoDatos.TipoRegistro || "Datos no disponible",
    TotalRegistro: restoDatos.TotalRegistro || "Datos no disponible",
    Vigencia: restoDatos.Vigencia || "Datos no disponible",
  };
};

function procesarDatosMidas(datos) {
  const datosCompletos = {
    "Acta Estratificación": "No disponible",
    "Area Construida": "No disponible",
    "Area Terreno": "No disponible",
    "Atipicidad Estratificación": "No disponible",
    "Barrio / Centro Poblado": "No disponible",
    "Clasificacion Suelo": "No disponible",
    "Codigo Dane": "No disponible",
    Direccion: "No disponible",
    Estrato: "No disponible",
    "Lado Dane": "No disponible",
    Localidad: "No disponible",
    "Manzana Igac": "No disponible",
    Matricula: "No disponible",
    "Nombre Edificación": "No disponible",
    Npn: "No disponible",
    "Observación Estratificación": "No disponible",
    "Predio Igac": "No disponible",
    Referencia: "No disponible",
    "Riesgo Principal": "No disponible",
    "Riesgo Secundario": "No disponible",
    Tratamiento: "No disponible",
    Ucg: "No disponible",
    Uso: "No disponible",
  };

  Object.keys(datosCompletos).forEach((campo) => {
    if (datos.hasOwnProperty(campo)) {
      datosCompletos[campo] = datos[campo] || "No disponible";
    }
  });

  return datosCompletos;
}

const client = algoliasearch("PF5MA0GQLN", "4b1b89b1457f96b3dda421647684a874");
const index = client.initIndex("macro2");

async function consultarInformacionPorNombre(termino) {
  let datosDeMidas = {};
  let datosBarriosDane = {};
  let datosEspecificosRdoscatrasto = {};
  let datosPensionados = [];
  let seleccion = {};
  let backgroundImageUrl = '';
  let direccionCompleta = '';

  try {
    if (termino.trim().length < 4) {
      Swal.fire(
        "Por favor, ingrese al menos 4 caracteres para la búsqueda.",
        "",
        "info"
      );
      return;
    }

    const algoliaResults = await index.search(termino.trim(), {});

    if (algoliaResults.nbHits === 0) {
      Swal.fire("No se encontraron resultados.", "", "info");
      return;
    }

    let resultados = algoliaResults.hits.map((hit) => ({
      Nombre: hit.Nombre,
      NoDocumentoGo: hit.NoDocumentoGo,
      AreaConstruida: hit["AreaConstruida (m2)"],
      AreaTerreno: hit["AreaTerreno (m2)"],
      Avaluo: hit["Avaluo ($)"],
      Comuna: hit.Comuna,
      Departamento: hit.Departamento,
      DestinoEconomico: hit.DestinoEconomico,
      DestinoEconomicoHomologado: hit.DestinoEconomicoHomologado,
      Direccion: hit.Direccion,
      EstadoCivil: hit.EstadoCivil,
      Municipio: hit.Municipio,
      NoOrden: hit.NoOrden,
      NoPredial: hit.NoPredial,
      NoPredialAnterior: hit.NoPredialAnterior,
      TipoDocumento: hit.TipoDocumento,
      TipoRegistro: hit.TipoRegistro,
      TotalRegistro: hit.TotalRegistro,
      Vigencia: hit.Vigencia,
    }));

    const opciones = {};
    resultados.forEach((resultado, index) => {
      opciones[
        index.toString()
      ] = `${resultado.Nombre} - Predial: ${resultado.NoPredial}`;
    });

    const { value: seleccionIndex } = await MySwal.fire({
      title: 'Seleccione un registro',
      input: 'select',
      inputOptions: opciones,
      inputPlaceholder: 'Seleccione un nombre',
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      customClass: {
        container: 'my-swal-container',
        title: 'my-swal-title',
        popup: 'my-swal-popup',
        input: 'my-swal-input',
        confirmButton: 'my-swal-confirm-button',
        cancelButton: 'my-swal-cancel-button',
      },
      inputValidator: (value) => {
        if (value) {
          return null;
        } else {
          return 'Debes seleccionar un registro para continuar';
        }
      },
    });

    if (seleccionIndex !== undefined) {
      seleccion = resultados[parseInt(seleccionIndex)];

      seleccion = Object.fromEntries(
        Object.entries(seleccion).map(([clave, valor]) => [
          clave,
          String(valor),
        ])
      );

      console.log("Registro seleccionado en runocatrstro:", seleccion);

      try {
        const response = await axios.post(
          "https://srv489501.hstgr.cloud:3200/consultamidas",
          { numeroPredial: seleccion.NoPredial },
          { timeout: 60000 }
        );

        if (response.data.data) {
          datosDeMidas = procesarDatosMidas(response.data.data);
        } else {
          console.error("No se encontraron datos en la respuesta de MIDAS.");
        }

        backgroundImageUrl = response.data.imageUrl; 
        console.log("URL de la imagen de fondo:", backgroundImageUrl);

        console.log("Datos procesados de MIDAS:", datosDeMidas);

        // Construir la dirección completa utilizando el mapeo
        const barrioMidas = datosDeMidas["Barrio / Centro Poblado"];
        const direccionMapeada = direccionMapeo[barrioMidas] || barrioMidas;
        direccionCompleta = `${direccionMapeada}, Cartagena de Indias, Provincia de Cartagena, Bolívar`;

        const qBarriosDane = query(
          collection(db, "barriosdane"),
          where("Barrio", "==", barrioMidas)
        );

        const querySnapshotBarriosDane = await getDocs(qBarriosDane);

        if (!querySnapshotBarriosDane.empty) {
          datosBarriosDane = querySnapshotBarriosDane.docs.map((doc) =>
            doc.data()
          );

          console.log(
            `Datos del barrio coincidente: ${JSON.stringify(datosBarriosDane)}`
          );
        } else {
          console.log(
            `No se encontraron datos en barriosdane para el barrio: ${barrioMidas}.`
          );
          datosBarriosDane = [];
        }

        const qRdoscatrasto = query(
          collection(db, "rdoscatrasto"),
          where("NoPredial", "==", seleccion.NoPredial)
        );
        const querySnapshotRdoscatrasto = await getDocs(qRdoscatrasto);

        if (!querySnapshotRdoscatrasto.empty) {
          const docRdoscatrasto = querySnapshotRdoscatrasto.docs[0];
          const datosRdoscatrasto = docRdoscatrasto.data();

          datosEspecificosRdoscatrasto = {
            "AreaConstruida (m2)":
              datosRdoscatrasto["AreaConstruida (m2)"] || "Datos no disponible",
            "AreaConstruidaTotal (m2)":
              datosRdoscatrasto["AreaConstruidaTotal (m2)"] ||
              "Datos no disponible",
            Departamento:
              datosRdoscatrasto["Departamento"] || "Datos no disponible",
            Estrato: datosRdoscatrasto["Estrato"] || "Datos no disponible",
            MatriculaInmobiliaria:
              datosRdoscatrasto["MatriculaInmobiliaria"] || "Datos no disponible",
            Municipio: datosRdoscatrasto["Municipio"] || "Datos no disponible",
            NoOrden: datosRdoscatrasto["NoOrden"] || "Datos no disponible",
            NoPredial: datosRdoscatrasto["NoPredial"] || "Datos no disponible",
            NoPredialAnterior:
              datosRdoscatrasto["NoPredialAnterior"] || "Datos no disponible",
            Puntaje: datosRdoscatrasto["Puntaje"] || "Datos no disponible",
            TipoRegistro:
              datosRdoscatrasto["TipoRegistro"] || "Datos no disponible",
            TotalRegistro:
              datosRdoscatrasto["TotalRegistro"] || "Datos no disponible",
            "Uso IGAC": datosRdoscatrasto["Uso IGAC"] || "Datos no disponible",
            "Uso LADM": datosRdoscatrasto["Uso LADM"] || "Datos no disponible",
            Vigencia: datosRdoscatrasto["Vigencia"] || "Datos no disponible",
          };

          console.log("Datos de rdoscatrasto:", datosEspecificosRdoscatrasto);
        } else {
          console.log(
            "No se encontraron datos en rdoscatrasto para el predial seleccionado."
          );
        }

        const NoDocumentoGoNumber = parseInt(seleccion.NoDocumentoGo);

        const qPensionados = query(
          collection(db, "pensionados"),
          where("CEDULA", "==", NoDocumentoGoNumber)
        );
        const querySnapshotPensionados = await getDocs(qPensionados);

        if (!querySnapshotPensionados.empty) {
          datosPensionados = querySnapshotPensionados.docs.map((doc) =>
            doc.data()
          );
          console.log("Datos de pensionados:", datosPensionados);
        } else {
          console.log("No se encontraron datos de pensionados.");
        }

        datosDeMidas = Object.fromEntries(
          Object.entries(datosDeMidas).map(([clave, valor]) => [
            clave,
            String(valor),
          ])
        );
        datosBarriosDane = datosBarriosDane.map((item) =>
          Object.fromEntries(
            Object.entries(item).map(([clave, valor]) => [clave, String(valor)])
          )
        );
        datosEspecificosRdoscatrasto = Object.fromEntries(
          Object.entries(datosEspecificosRdoscatrasto).map(([clave, valor]) => [
            clave,
            String(valor),
          ])
        );
        datosPensionados = datosPensionados.map((item) =>
          Object.fromEntries(
            Object.entries(item).map(([clave, valor]) => [clave, String(valor)])
          )
        );

        const resultado = {
          seleccion: seleccion,
          datosDeMidas: datosDeMidas,
          datosBarriosDane: datosBarriosDane,
          datosEspecificosRdoscatrasto: datosEspecificosRdoscatrasto,
          datosPensionados: datosPensionados,
          backgroundImageUrl: backgroundImageUrl, 
          direccionCompleta: direccionCompleta 
        };

        console.log("Resultados finales:", resultado);
        return resultado;

      } catch (error) {
        console.error("Error al realizar la consulta a MIDAS:", error);
      }
    } else {
      console.log("No se ha seleccionado ningún registro.");
    }
  } catch (error) {
    console.error("Error al consultar la información por nombre:", error);
    Swal.fire("Error", "No se pudo completar la consulta", "error");
  }
}

export default consultarInformacionPorNombre;
