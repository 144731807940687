import React from "react";

const FeatureSection = () => {
  return (
    <section className="feature-section-two">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-7">
            <div className="feature-content-box">
              <div className="tj-sec-heading">
                <h2 className="title">
                  Impulsando el Futuro en{" "}
                  <span className="active-color">Cartagena de Indias</span>
                </h2>
                <p className="desc">
                  Descubre cómo los megaproyectos están transformando Cartagena
                  de Indias en un referente de desarrollo sostenible, innovación
                  y crecimiento económico, aportando al bienestar de sus
                  habitantes y posicionando a la ciudad en el escenario global.
                </p>
              </div>
              <div className="row">
                {/* Megaproyecto 1 */}
                <div className="col-lg-6 col-md-6">
                  <div className="feature-item-two hover-shape-border">
                    <div className="feature-content">
                      <h5 className="title">
                        <a className="title-link" href="#">
                          {" "}
                          Expansión Portuaria
                        </a>
                      </h5>
                      <p className="desc">
                        Ampliación y modernización del puerto para aumentar la
                        capacidad y eficiencia en el manejo de cargas,
                        fomentando el comercio internacional.
                      </p>
                    </div>
                  </div>
                </div>
                {/* Megaproyecto 2 */}
                <div className="col-lg-6 col-md-6">
                  <div className="feature-item-two margin_top hover-shape-border">
                    <div className="feature-content">
                      <h5 className="title">
                        <a className="title-link" href="#">
                          {" "}
                          Parques Eólicos Marinos
                        </a>
                      </h5>
                      <p className="desc">
                        Implementación de energía eólica marina para promover el
                        uso de energías renovables y reducir la huella de
                        carbono de la ciudad.
                      </p>
                    </div>
                  </div>
                </div>
                {/* Megaproyecto 3 */}
                <div className="col-lg-6 col-md-6">
                  <div className="feature-item-two hover-shape-border">
                    <div className="feature-content">
                      <h5 className="title">
                        <a className="title-link" href="#">
                          {" "}
                          Renovación Urbana
                        </a>
                      </h5>
                      <p className="desc">
                        Proyectos de revitalización de áreas históricas y
                        desarrollo de nuevas zonas urbanas con infraestructura
                        moderna y sostenible.
                      </p>
                    </div>
                  </div>
                </div>
                {/* Megaproyecto 4 */}
                <div className="col-lg-6 col-md-6">
                  <div className="feature-item-two margin_top hover-shape-border">
                    <div className="feature-content">
                      <h5 className="title">
                        <a className="title-link" href="#">
                          {" "}
                          Corredores Logísticos
                        </a>
                      </h5>
                      <p className="desc">
                        Desarrollo de infraestructura vial y logística para
                        mejorar la conectividad y eficiencia en el transporte de
                        bienes y personas.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="feature-group-image">
              <div className="feature-text">
                <i className="flaticon-checkmark"></i> Innovación Tecnológica
              </div>
              <div className="feature-text-two">
                <i className="flaticon-checkmark"></i> Sostenibilidad Ambiental
              </div>
              {/* Actualizar con imágenes relevantes de megaproyectos o aspectos característicos de Cartagena */}
              <img
                className="image-1 shake-y"
                src="https://jetsemani.imgix.net/wp-content/uploads/2017/02/CARTAGENA-FERNANDO-PARRA.jpg?fit=crop&auto=format&q=50&w=undefined&h=undefined"
                alt="Cartagena"
              />
              <img
                className="image-2 pulse"
                src="https://cdn.getyourguide.com/img/location/5cced3a1a6f81.jpeg/88.jpg"
                alt="Proyecto en Cartagena"
              />
              <img
                className="image-3 pulse"
                src="https://www.kayak.com.co/c/wp-content/uploads/sites/210/2020/04/intro-mor.jpg"
                alt="Innovación"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="feature-shape">
        <img src="assets/images/shape/overly-11.svg" alt="Shape" />
      </div>
      <div className="feature-shape1 shake-y">
        <img src="assets/images/shape/sec-shape11.svg" alt="Shape" />
      </div>
    </section>
  );
};

export default FeatureSection;
