import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { uploadDataToFirebase } from '../services/saveDataToFirestore';
import { actualizarEstadoCivil } from '../services/actualizarEstadoCivilService';

const styles = {
  container: {
    width: '50vw',
    height: '50vh',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '60px',
    marginLeft: '20px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    textAlign: 'center'
  },
  input: {
    width: '100%',
    padding: '10px',
    fontSize: '16px',
    border: '1px solid #ccc',
    boxShadow: 'inset 0px 0px 8px rgba(0,0,0,0.1)'
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    backgroundColor: '#800080',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    marginTop: '10px'
  },
  fileList: {
    textAlign: 'left',
    marginLeft: '20px'
  }
};

function FileUploadComponent() {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadCount, setUploadCount] = useState(0);

 
  
  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
    setUploadCount(0); // Reset the counter when new files are selected
  };

  const handleFileUpload = () => {
    Array.from(selectedFiles).forEach(file => {
      readExcelFile(file, async (jsonData) => {
        await uploadDataToFirebase(file.name, jsonData, (docId) => {
          setUploadCount((prevCount) => prevCount + 1); // Update the state each time a document is uploaded
        });
      });
    });
  };

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <h4>Cargar Archivos</h4>
        <input
          style={styles.input}
          type="file"
          multiple
          onChange={handleFileChange}
        />
        <button
          style={styles.button}
          onClick={handleFileUpload}
        >
          Cargar
        </button>
        <p>Total de documentos subidos: {uploadCount}</p>
        {selectedFiles.length > 0 && (
          <div style={styles.fileList}>
            <h5>Archivos seleccionados:</h5>
            <ul>
              {Array.from(selectedFiles).map((file, index) => (
                <li key={index}>{file.name}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

function readExcelFile(file, callback) {
  const reader = new FileReader();
  reader.onload = (e) => {
    const data = e.target.result;
    const workbook = XLSX.read(data, { type: 'binary' });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    const headers = jsonData[0];
    const dataRows = jsonData.slice(1);

    const jsonObjects = dataRows.map((row) => {
      let obj = {};
      headers.forEach((header, index) => {
        obj[header] = row[index];
      });
      return obj;
    });

    callback(jsonObjects);
  };

  reader.onerror = (ex) => {
    console.error('Error reading file', ex);
  };

  reader.readAsBinaryString(file);
}

export default FileUploadComponent;
