import React, { useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Modal,
  TextField,
  Stack,
} from '@mui/material';

function PensionadosModal({ open, onClose, pensionados, onSendConsulta }) {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSendConsulta = (nombre) => {
    onSendConsulta(nombre);
    onClose();
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredPensionados = pensionados.filter(pensionado =>
    pensionado.Nombre.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '60%',
        maxHeight: '80%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        overflow: 'auto'
      }}>
        <Typography variant="h6" component="h2" gutterBottom>
          Lista de Pensionados
        </Typography>
        <Stack direction="row" spacing={2} sx={{ mb: 2, alignItems: 'center' }}>
          <TextField
            label="Buscar Pensionado"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{ width: '50%' }}
          />
          <Typography variant="subtitle1">
            {`Total: ${filteredPensionados.length}`}
          </Typography>
        </Stack>
        <TableContainer component={Paper}>
          <Table aria-label="pensionados table">
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Nombre</TableCell>
                <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>NoPredial</TableCell>
                <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>NoDocumentoGo</TableCell>
                <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredPensionados.map((pensionado, index) => (
                <TableRow key={index} hover>
                  <TableCell>{pensionado.Nombre}</TableCell>
                  <TableCell>{pensionado.NoPredial}</TableCell>
                  <TableCell>{pensionado.NoDocumentoGo}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleSendConsulta(pensionado.Nombre)}
                    >
                      Enviar Consulta
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              {filteredPensionados.length === 0 && (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    No se encontraron resultados
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  );
}

export default PensionadosModal;
