import React, { useState } from 'react';
import EmailService from '../services/EmailService';
import Swal from 'sweetalert2';

const ContactSection = () => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        subject: '',
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await EmailService.sendEmail(formData);
            Swal.fire({
                title: '¡Enviado!',
                text: 'Mensaje enviado con éxito',
                icon: 'success',
                confirmButtonText: 'Aceptar'
            });
            // Opcional: Limpiar el formulario después del envío
            setFormData({
                name: '',
                phone: '',
                email: '',
                subject: '',
            });
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: 'Hubo un error al enviar el mensaje',
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
        }
    };


    return (
        <section id="contact-section" className="tj-contact-section contact-style-two">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="tj-contact-content-one">
                            <div className="tj-sec-heading">
                                <span className="sub-title">Contáctanos</span>
                                <h2 className="title">¿Estás interesado en conocer los proyectos?</h2>
                                <p className="desc">
                                    Escríbenos para saber más sobre los proyectos que están impactando a la ciudad de Cartagena de Indias.
                                </p>
                            </div>
                            <div className="image-box hover-shape-border">
                                <img className="img-1" src="https://www.rotasturisticas.com/imagens/destinos/co/large/IMGD11-93-6211-cartagena_das_indias.jpg" alt="Imagen de Cartagena" />
                                <div className="testimonial-item-shape">
                                    <span className="border-shadow shadow-1"></span>
                                    <span className="border-shadow shadow-2"></span>
                                </div>
                                <div className="box-shape pulse">
                                    <img src="assets/images/shape/sec-shape5.svg" alt="Shape" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="tj-contact-box">
                            <form onSubmit={handleSubmit}>
                                <div className="form-input">
                                    <i className="flaticon-user"></i>
                                    <input
                                        className="input-fill"
                                        type="text"
                                        id="name"
                                        name="name"
                                        placeholder="Nombre"
                                        required=""
                                        value={formData.name}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-input">
                                    <i className="flaticon-telephone"></i>
                                    <input
                                        className="input-fill"
                                        type="tel"
                                        id="phone"
                                        name="phone"
                                        placeholder="Teléfonos"
                                        required=""
                                        value={formData.phone}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-input">
                                    <i className="flaticon-mail"></i>
                                    <input
                                        className="input-fill"
                                        type="email"
                                        id="email"
                                        name="email"
                                        placeholder="Dirección de correo"
                                        required=""
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-input">
                                    
                                    <textarea
                                        className="input-fill"
                                        id="subject"
                                        name="subject"
                                        placeholder="Mensaje"
                                        required=""
                                        value={formData.subject}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="tj-contact-button">
                                    <button className="tj-primary-btn contact-btn" type="submit">
                                        Solicitar más información
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contact-bg-shape">
                <img src="assets/images/shape/sec-shape6.svg" alt="Shape" />
            </div>
            <div className="sec-overly-1">
                <img src="assets/images/shape/overly-1.svg" alt="Shape" />
            </div>
            <div className="sec-overly-2">
                <img src="assets/images/shape/overly-2.svg" alt="Shape" />
            </div>
            <div className="tj-circle-box3">
                <span className="circle-1"></span>
                <span className="circle-2"></span>
                <span className="circle-3"></span>
                <span className="circle-4"></span>
            </div>
        </section>
    );
};

export default ContactSection;
