import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { auth } from '../firebase';

// Registrar un nuevo usuario
export const registerWithEmailPassword = async (email, password) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    console.log("Usuario registrado con éxito:", userCredential.user);
    // Aquí puedes manejar la redirección o actualización del estado del usuario
  } catch (error) {
    console.error("Error al registrar el usuario:", error.message);
    // Aquí puedes manejar los errores, como mostrar un mensaje al usuario
  }
};

// Iniciar sesión con email y contraseña
export const loginWithEmailPassword = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    console.log("Inicio de sesión exitoso:", userCredential.user);
    // Aquí puedes manejar la redirección o actualización del estado del usuario
  } catch (error) {
    console.error("Error al iniciar sesión:", error.message);
    // Aquí puedes manejar los errores, como mostrar un mensaje al usuario
  }
};

// Cerrar sesión
export const logout = async () => {
    try {
      await signOut(auth);
      console.log("Sesión cerrada con éxito");
      // Aquí puedes manejar la redirección o limpiar el estado del usuario
    } catch (error) {
      console.error("Error al cerrar sesión:", error.message);
      // Manejar errores de cierre de sesión aquí
    }
  };
  