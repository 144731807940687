import React, { useState } from "react";

import Swal from "sweetalert2";
import { loginWithEmailPassword } from "../services/Authservices";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault(); // Evita el comportamiento por defecto del formulario
    try {
      await loginWithEmailPassword(email, password);
      Swal.fire({
        title: "Inicio de sesión exitoso",
        text: "Has iniciado sesión correctamente",
        icon: "success",
        confirmButtonText: "Ok",
      });
      // Aquí puedes redireccionar al usuario o manejar el estado global de autenticación
    } catch (error) {
      Swal.fire({
        title: "Error al iniciar sesión",
        text: error.message,
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

  return (
    <section className="tj-contact-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="tj-contact-content-one">
              <div className="tj-sec-heading">
                <span className="sub-title">Login</span>
                <h2 className="title">Welcome Back!</h2>
                <p className="desc">
                  Please enter your details to sign in and continue.
                </p>
              </div>
              <div className="image-box hover-shape-border">
                <img
                  className="img-1"
                  src="assets/images/progress/contact-image.png"
                  alt="Login"
                />
                <div className="box-shape pulse">
                  <img src="assets/images/shape/sec-shape5.svg" alt="Shape" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="tj-contact-box">
              <form onSubmit={handleLogin}>
                <div className="form-input">
                  <i className="flaticon-mail"></i>
                  <input
                    className="input-fill"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email address"
                    required
                  />
                </div>
                <div className="form-input">
                  <i className="flaticon-lock"></i>
                  <input
                    className="input-fill"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    required
                  />
                </div>
                <div className="tj-contact-button">
                  <button className="tj-primary-btn contact-btn" type="submit">
                    Sign In
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Formas y decoraciones */}
    </section>
  );
};

export default Login;
