import React from "react";

const CtaSection = () => {
    return (
        <section className="tj-cta-section-two">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="cta-content-two">
                            <div className="cta-text">
                                <h2 className="title">Revitalizando la Ciénaga de la Virgen en Cartagena</h2>
                                <div className="active-button">
                                    <ul className="list-gap">
                                        <li className="list-1"><i className="flaticon-ecology"></i> Conservación Ambiental</li>
                                        <li className="list-2"><i className="flaticon-community"></i> Desarrollo Comunitario</li>
                                        <li className="list-3"><i className="flaticon-sustainability"></i> Sostenibilidad</li>
                                    </ul>
                                </div>
                                <p className="desc">
                                    El proyecto de la Ciénaga de la Virgen apunta a la restauración ecológica y la promoción de prácticas sostenibles para proteger este valioso ecosistema. A través de iniciativas colaborativas, buscamos no solo conservar la biodiversidad sino también fortalecer la economía local y mejorar la calidad de vida de las comunidades circundantes.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cta-bg-image shake-y">
                <img src="https://i.ibb.co/K6py1ks/Sin-t-tulo-1.jpg" alt="Ciénaga de la Virgen" />
            </div>
            <div className="cta-overly">
                <img src="assets/images/shape/overly-14.svg" alt="Decoración" />
            </div>
            <div className="tj-star-group">
                <img className="icon-1" src="assets/images/icon/star-5.png" alt="Icono" />
                <img className="icon-2" src="assets/images/icon/star-6.png" alt="Icono" />
                <img className="icon-3" src="assets/images/icon/star-7.png" alt="Icono" />
                <img className="icon-4" src="assets/images/icon/star-8.png" alt="Icono" />
            </div>
        </section>
    );
}

export default CtaSection;
