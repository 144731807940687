import React from 'react';

const AboutSection = () => {
  return (
    <section className="tj-counter-section-two">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="counter-content-box-two">
              {/* Megaproyecto: Puerto de Aguas Profundas */}
              <div className="counter-item">
                
                <div className="counter-number">
                  <div className="tj-count"><span className="odometer" data-count="20">0</span>B+</div>
                  <span className="sub-title"> Inversión en USD</span>
                </div>
              </div>
              
              {/* Infraestructura Vial */}
              <div className="counter-item">
               
                <div className="counter-number">
                  <div className="tj-count"><span className="odometer" data-count="150">0</span>+</div>
                  <span className="sub-title"> Kilómetros de Vías</span>
                </div>
              </div>

              {/* Parques Eólicos */}
              <div className="counter-item">
               
                <div className="counter-number">
                  <div className="tj-count"><span className="odometer" data-count="500">0</span>MW</div>
                  <span className="sub-title"> Capacidad Generada</span>
                </div>
              </div>

              {/* Renovación Urbana */}
              <div className="counter-item">
                
                <div className="counter-number">
                  <div className="tj-count"><span className="odometer" data-count="95">0</span>%</div>
                  <span className="sub-title"> Proyectos Completados</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
