// src/store.js

import { createStore } from 'redux';

// Un reducer de ejemplo que devuelve el estado sin cambios
const reducer = (state = {}, action) => {
  return state;
};

const store = createStore(reducer);

export default store;
