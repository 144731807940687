import React from 'react';
import {
  Typography,
  Paper,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
} from '@mui/material';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import GoogleMapComponent from '../components/GoogleMapComponent';

const defaultLat = 10.391049;
const defaultLng = -75.479426;

function DatosTabla({ datos, source, imagenUrl, direccion }) {
  const formatDisplayValue = (value) => {
    if (Array.isArray(value)) {
      return value.map((v) => (typeof v === 'object' ? JSON.stringify(v) : String(v))).join(', ');
    }
    if (value && typeof value === 'object') {
      return JSON.stringify(value, null, 2);
    }
    return String(value);
  };

  const renderCVData = (data, keyPrefix = '') => {
    return Object.entries(data).flatMap(([key, value], index) => {
      let formattedKey = key.replace(/([A-Z])/g, ' $1').trim();
      formattedKey = formattedKey.toUpperCase();

      if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
        return renderCVData(value, `${keyPrefix}${formattedKey} `);
      } else {
        return (
          <TableRow key={`${keyPrefix}${key}-${index}`}>
            <TableCell
              component='th'
              scope='row'
              style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
            >
              {keyPrefix ? `${keyPrefix}${formattedKey}` : formattedKey}
            </TableCell>
            <TableCell align='right'>{formatDisplayValue(value)}</TableCell>
          </TableRow>
        );
      }
    });
  };

  const titleMap = {
    seleccion: '',
    midas: 'DATOS DE MIDAS',
    barriosDane: 'DATOS DEL BARRIO',
    rdoscatrasto: 'DATOS CATASTRO',
    datosPensionados: 'DATOS DE PENSIÓN',
  };

  const getTitle = () => {
    const baseTitle = titleMap[source] || '';
    return baseTitle && datos.Nombre ? `${baseTitle} - ${datos.Nombre}` : baseTitle || datos.Nombre || '';
  };

  if (!datos) {
    if (source === 'datosPensionados') {
      return (
        <Typography variant='subtitle1' style={{ marginTop: '20px' }}>
          No hay datos de pensión disponibles para este usuario.
        </Typography>
      );
    }
    return (
      <Typography variant='subtitle1' style={{ marginTop: '20px' }}>
        No hay datos disponibles para mostrar.
      </Typography>
    );
  }

  const renderedData = renderCVData(datos);
  const halfwayPoint = Math.ceil(renderedData.length / 2);
  const columnOneData = renderedData.slice(0, halfwayPoint);
  const columnTwoData = renderedData.slice(halfwayPoint);

  return (
    <Paper
      elevation={3}
      style={{ padding: '20px', marginTop: '20px', overflow: 'auto' }}
    >
      <Typography
        variant='h5'
        gutterBottom
        style={{
          marginBottom: '20px',
          backgroundColor: '#add8e6',
          fontWeight: 'bold',
          padding: '10px',
        }}
      >
        {getTitle()}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TableContainer component={Box}>
            <Table aria-label='customized table'>
              <TableBody>{columnOneData}</TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TableContainer component={Box}>
            <Table aria-label='customized table'>
              <TableBody>{columnTwoData}</TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      {source === 'midas' && imagenUrl && (
        <Box mt={2}>
          <Typography variant="h6">Ubicación Mapa MIDAS</Typography>
          <Zoom>
            <img src={imagenUrl} alt="Imagen de fondo" style={{ maxWidth: '100%' }} />
          </Zoom>
        </Box>
      )}
      {source === 'midas' && (
        <Box mt={2}>
          <Typography variant="h6">Realizar recorrido y verificacion del predio:</Typography>
          <GoogleMapComponent address={direccion} />
        </Box>
      )}
    </Paper>
  );
}

export default DatosTabla;
