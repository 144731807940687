import { db, collection, getDocs } from "../firebase";
import Swal from "sweetalert2";

async function consultarPensionados() {
  try {
    const querySnapshot = await getDocs(collection(db, "pensionadosCatrastro"));
    const pensionados = querySnapshot.docs.map(doc => doc.data());
    return pensionados;
  } catch (error) {
    console.error("Error al consultar los pensionados:", error);
    Swal.fire("Error", "No se pudo completar la consulta", "error");
    throw error;
  }
}

export default consultarPensionados;
