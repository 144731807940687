import React from "react";

const VideoSection = () => {
    return (<section class="tj-video-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="tj-video-area text-center">
                    <div class="tj-video-popup">
                        <div class="circle pulse video-icon">
                            <a
                                class="venobox popup-videos-button"
                                data-autoplay="true"
                                data-vbtype="video"
                                href="https://drive.google.com/file/d/1hCAFtvqjahdZhv9z-_ECmzI0n9ndXRjI/view?pli=1"
                            >
                                <img src="assets/images/icon/play.svg" alt="Play" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
);
}
export default VideoSection;