// EmailService.js
import axios from "axios";

const API_URL = "https://srv489501.hstgr.cloud:3200/send-email"; // Asegúrate de usar tu URL correcta

const sendEmail = async (emailData) => {
  try {
    const response = await axios.post(API_URL, emailData);
    return response.data;
  } catch (error) {
    console.error("Error al enviar el email", error);
    throw error;
  }
};

export default {
  sendEmail,
};
