import { db, collection, addDoc } from "../firebase"; // Asegúrate de ajustar la ruta de importación según la estructura de tu proyecto

// Función para reemplazar valores undefined por null en un objeto
function replaceUndefinedWithNull(object) {
  const keys = Object.keys(object);
  keys.forEach((key) => {
    if (object[key] === undefined) {
      object[key] = null; // Reemplaza undefined por null
    }
  });
  return object;
}

// Función para formatear el nombre del archivo
function formatFileName(fileName) {
  // Elimina la extensión del archivo (todo después del último punto)
  const nameWithoutExtension =
    fileName.substring(0, fileName.lastIndexOf(".")) || fileName;
  // Convierte a minúsculas, elimina números, espacios y caracteres especiales (solo permite letras a-z)
  return nameWithoutExtension.toLowerCase().replace(/[^a-z]+/g, "");
}

// Asegúrate de que este archivo se encuentra en el directorio correcto dentro de tu proyecto
export async function uploadDataToFirebase(originalFileName, data, onUpload) {
  const fileName = formatFileName(originalFileName);
  const collectionRef = collection(db, fileName);

  for (const item of data) {
    const itemWithNoUndefined = replaceUndefinedWithNull(item);
    try {
      const docRef = await addDoc(collectionRef, itemWithNoUndefined);
      onUpload(docRef.id); // Llama al callback pasándole el ID del documento subido
    } catch (error) {
      // Puedes decidir qué hacer con los errores aquí, por ejemplo, enviarlos al callback con un valor especial o manejarlos de otra forma
    }
  }
}