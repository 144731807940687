import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  CircularProgress,
  Typography,
  Box,
  Stack,
} from "@mui/material";

import consultarInformacionPorNombre from "../services/consulta";
import consultarPensionados from "../services/consultarPensionados";
import DatosTabla from "../services/consultarInformacion";
import PensionadosModal from "./PensionadosModal";

function ConsultaComponent() {
  const [consulta, setConsulta] = useState("");
  const [resultadoConsulta, setResultadoConsulta] = useState(null);
  const [cargando, setCargando] = useState(false);
  const [esConsultaNumerica, setEsConsultaNumerica] = useState(false);
  const [pensionados, setPensionados] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const handleChange = (event) => {
    setConsulta(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setCargando(true);

    try {
      const esNumerico = !isNaN(consulta) && !consulta.includes(" ");
      setEsConsultaNumerica(esNumerico);
      const resultado = await consultarInformacionPorNombre(consulta);
      setResultadoConsulta(resultado);
    } catch (error) {
      console.error("Error al realizar la consulta:", error);
    } finally {
      setCargando(false);
    }
  };

  const handleLimpiar = () => {
    setConsulta("");
    setResultadoConsulta(null);
    setCargando(false);
    setEsConsultaNumerica(false);
  };

  const handleVerPensionados = async () => {
    setCargando(true);
    try {
      const pensionados = await consultarPensionados();
      setPensionados(pensionados);
      setOpenModal(true);
    } catch (error) {
      console.error("Error al obtener los datos de pensionados:", error);
    } finally {
      setCargando(false);
    }
  };

  const handleSendConsulta = (nombre) => {
    setConsulta(nombre);
    setOpenModal(false);
  };

  const renderDatosTabla = () => {
    if (!resultadoConsulta) {
      return null;
    }

    return (
      <>
        {resultadoConsulta.seleccion && <DatosTabla datos={resultadoConsulta.seleccion} source="seleccion" />}
        {resultadoConsulta.datosDeMidas && (
          <DatosTabla
            datos={resultadoConsulta.datosDeMidas}
            source="midas"
            imagenUrl={resultadoConsulta.backgroundImageUrl}
            direccion={resultadoConsulta.direccionCompleta}
          />
        )}
        {resultadoConsulta.datosBarriosDane && <DatosTabla datos={resultadoConsulta.datosBarriosDane} source="barriosDane" />}
        {resultadoConsulta.datosEspecificosRdoscatrasto && <DatosTabla datos={resultadoConsulta.datosEspecificosRdoscatrasto} source="rdoscatrasto" />}
        {resultadoConsulta.datosPensionados && <DatosTabla datos={resultadoConsulta.datosPensionados} source="datosPensionados" />}
      </>
    );
  };

  return (
    <Container maxWidth="lg" style={{ marginTop: "150px" }}>
      <Box sx={{ width: "100%" }}>
        <Typography variant="h5" sx={{ my: 2 }}>
          Realiza una Consulta
        </Typography>
        <form onSubmit={handleSubmit} style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <TextField
            id="consulta-input"
            label="Escribe tu consulta aquí"
            variant="outlined"
            value={consulta}
            onChange={handleChange}
            style={{ width: "50%" }}
          />
          <Stack direction="row" spacing={2}>
            <Button variant="contained" color="primary" type="submit" disabled={cargando || consulta.trim() === ""}>
              {cargando ? <CircularProgress size={24} /> : "Enviar Consulta"}
            </Button>
           
            <Button variant="contained" color="secondary" onClick={handleVerPensionados}>
              Ver Pensionados
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleLimpiar} disabled={cargando}>
              Limpiar
            </Button>
          </Stack>
        </form>
        {cargando ? (
          <Typography sx={{ my: 2 }}>
            Recopilando información, por favor espere...
          </Typography>
        ) : (
          renderDatosTabla()
        )}
      </Box>
      <PensionadosModal 
        open={openModal} 
        onClose={() => setOpenModal(false)} 
        pensionados={pensionados} 
        onSendConsulta={handleSendConsulta} 
      />
    </Container>
  );
}

export default ConsultaComponent;
