import React from 'react';
import { Link } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase'; // Asegura que esta ruta es correcta para tu configuración de Firebase

const MenuData = () => {
  // Obtiene el estado de autenticación del usuario
  const [user] = useAuthState(auth);

  // Define los elementos del menú, incluyendo condicionalmente los elementos basados en la autenticación del usuario
  let menuItems = [
    {
      title: "Inicio",
      link: "/",
    },
    {
      title: "Proyectos",
      link: "/proyectos",
    },
    // Solo muestra estos elementos si el usuario está autenticado
    ...(user ? [
      {
        title: "Consultas",
        link: "/consultas",
      },
      {
        title: "Cargar Datos",
        link: "/cargardatos",
      }
    ] : []),
    {
      title: "Contacto",
      link: "#contact-section",
    },
  ];

  // Maneja el clic en los enlaces internos de la página para un desplazamiento suave
  const handleNavLinkClick = (link) => {
    if (link.startsWith('#')) {
      // Previene el comportamiento predeterminado del enlace
      const id = link.substring(1); // Elimina el símbolo '#'
      const section = document.getElementById(id);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <ul className="main-menu">
      {menuItems.map((item, index) => (
        <li key={index}>
          {item.link.startsWith('#') ? (
            // Utiliza onClick para enlaces internos de la página (es decir, enlaces que comienzan con '#')
            <a href={item.link} onClick={(e) => {e.preventDefault(); handleNavLinkClick(item.link);}}>{item.title}</a>
          ) : (
            // Utiliza <Link> para el enrutamiento interno
            <Link to={item.link}>{item.title}</Link>
          )}
        </li>
      ))}
    </ul>
  );
};

export default MenuData;
