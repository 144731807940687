import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Login from '../components/Login';
import Home from '../components/home';
import ConsultasGenerales from '../components/ConsultasGenerales';
import FileUploadComponent from '../components/FileUpload';
// Importa otros componentes que necesites

const AppRouter = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path ="/consultas" element={<ConsultasGenerales />} />
      <Route path ="/cargardatos" element={<FileUploadComponent/>} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default AppRouter;
