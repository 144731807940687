import React from "react";
import { BrowserRouter as Router } from "react-router-dom";


import Header from "./shareds/Header";
import Sidebar from "./shareds/Sidebas";
import Footer from "./shareds/Footer";
import AppRouter from "./shareds/RutasApp";

function App() {
  return (
    
    <Router> {/* Envuelve tu aplicación en un único Router */}
      <Header />
      <main className="site-content">
        
        <AppRouter />
      </main>
      <Footer />
    </Router>
  );
}

export default App
