
import { collection, getDocs, updateDoc, doc } from "firebase/firestore";
import { db } from "../firebase"; // Asegúrate de que esta ruta sea correcta

export const actualizarDocumentos = async () => {
  const querySnapshot = await getDocs(collection(db, "runocatrstro"));
  
  let totalActualizados = 0; // Contador de documentos actualizados

  const updates = querySnapshot.docs.map((docSnapshot, index) => {
    console.log(`Actualizando documento ${index + 1} de ${querySnapshot.docs.length}`);
    totalActualizados++;
    return updateDoc(doc(db, "runocatrstro", docSnapshot.id), {
      actualizado: true
    });
  });

  try {
    await Promise.all(updates);
    console.log(`${totalActualizados} documentos han sido actualizados.`);
  } catch (error) {
    console.error("Error actualizando documentos: ", error);
  }
};
