import React from "react";

const Sidebar = () => {
  return (
    <>
      <section
        class="tj-hero-section-three"
        data-bg-image="assets/images/shape/group-overly3.svg"
      >
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="hero-left-content">
                <div class="hero-content-three">
                  <div class="tj-sec-heading-two">
                    <h1 class="title">
                      Transforma la Ciudad de
                      <span class="active-color"> Cartagena</span>
                    </h1>
                    <p class="desc">
                      Descubre cómo los proyectos están cambiando la ciudad de
                      Cartagena de Indias, mejorando la calidad de vida y
                      transformando su futuro. ¡Únete a nosotros en esta
                      emocionante jornada de desarrollo urbano!
                    </p>
                  </div>
                </div>
                {/* <div class="hero-middle-area">
                                <div class="hero-input-form-two">
                                    <div class="hero-input">
                                        <input
                                            type="email"
                                            id="mail"
                                            name="mail"
                                            placeholder="Enter your email address"
                                            required=""
                                        />
                                    </div>
                                    <div class="tj-hero-button">
                                        <button class="tj-primary-black-btn" type="submit" value="submit">
                                            Start for free
                                        </button>
                                    </div>
                                </div>
                                <div class="header-list">
                                    <ul class="list-gap">
                                        <li><i class="flaticon-checkmark"></i> No cards required</li>
                                        <li><i class="flaticon-checkmark"></i> 30 day free trial</li> 
                                    </ul>
                                </div>
                            </div> */}
              </div>
            </div>
            <div class="col-lg-6">
              <div class="hero-bg-image shake-y">
                <img
                  src="https://s3-us-west-2.amazonaws.com/denomades/blog/wp-content/uploads/2020/05/11141948/ca-times.brightspotcdn.com_.jpg"
                  alt="Image"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="tj-star-box">
          <img class="star-1" src="assets/images/icon/star-1.png" alt="Icons" />
          <img class="star-2" src="assets/images/icon/star-2.png" alt="Icons" />
          <img class="star-3" src="assets/images/icon/star-3.png" alt="Icons" />
          <img class="star-4" src="assets/images/icon/star-3.png" alt="Icons" />
          <img class="star-5" src="assets/images/icon/star-4.png" alt="Icons" />
          <img class="star-6" src="assets/images/icon/star-2.png" alt="Icons" />
          <img class="star-7" src="assets/images/icon/star-4.png" alt="Icons" />
        </div>
      </section>
    </>
  );
};

export default Sidebar;
