import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '400px'
};

const defaultCenter = {
  lat: 10.391049,
  lng: -75.479426
};

function GoogleMapComponent({ address }) {
  console.log('esta es la direccion', address);
  const [coordinates, setCoordinates] = useState(defaultCenter);

  useEffect(() => {
    if (address) {
      const fetchCoordinates = async () => {
        try {
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=AIzaSyADkILxXgusP6VfyKExcU5Z7tVivY1e4RE`
          );
          const data = await response.json();
          if (data.results && data.results[0]) {
            const location = data.results[0].geometry.location;
            setCoordinates({ lat: location.lat, lng: location.lng });
          }
        } catch (error) {
          console.error('Error al obtener las coordenadas:', error);
        }
      };

      fetchCoordinates();
    }
  }, [address]);

  return (
    <LoadScript googleMapsApiKey="AIzaSyADkILxXgusP6VfyKExcU5Z7tVivY1e4RE">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={coordinates}
        zoom={20} // Ajusta este valor para acercar el mapa
        mapTypeId="satellite" // Cambia el tipo de mapa a satélite
      >
        <Marker position={coordinates} />
      </GoogleMap>
    </LoadScript>
  );
}

export default React.memo(GoogleMapComponent);
