// src/components/Footer.js

import React from "react";
import AboutSection from "./AboutSections";
import FeatureSection from "./FeatureSection";
import ContactSection from "./ContactSection";
import TeamSection from "./TeamSection";
import CtaSection from "./CtaSection";
import VideoSection from "./VideoSection";
import Sidebar from "../shareds/Sidebas";

const Home = () => {
  return (
    <>
    <Sidebar />
      <AboutSection />
      <FeatureSection />
      <VideoSection />
      <CtaSection />
      <TeamSection />
      <ContactSection />
    </>
  );
};

export default Home;
